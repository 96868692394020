export default {

    en: {
        // Login
        login: 'Login',
        username: 'Username',
        password: 'Password',
        lostpassword: 'Lost Password',
        passwordreset: 'Password Reset Sent',
        passwordresetmessage: "Please enter the email address associated with your account. If you don't know this please contact ",
        sendpasswordreset: 'Send Password Reset',
        resetsent: 'Password Reset Sent',
        resetsentmessage: 'Please check your email for your password reset link.',
        setnewpassword: 'Set New Password',
        newpassword: 'New Password',
        confirmnewpassword: 'Confirm New Password',
        resetsuccessful: 'Password Reset Successful',
        pleaselogin: 'Please login with your new password.',
        // Side bar menu
        quotesbookings: 'Existing Quotes & Bookings',
            reference: 'Reference',
            yourreference: 'Your Reference',
            bookingname: 'Booking Name',
            traveldatefrom: 'Travel Date From',
            traveldateto: 'Travel Date To',
            filter: 'Filter Results',
        customquote: 'Create Quote/Booking',
        quickquote: 'Create Quick Quote',
            duration: 'Duration',
            startlocation: 'Start Location',
            transporttype: 'Transport Type',
            any: 'Any',
            nights: 'Nights',
            rentalcar: 'Rental Car',
            seatincoach: 'Seat in Coach',
            privatetransfer: 'Private Transfer',
        tariff: 'Product Tariff',
        ourTools: 'Our Tools',
        tourplanTools: 'Tourplan Tools',
        logout: 'Logout',
        documentation: 'Documentation',
        search: 'Search',
        reset: 'Reset',
        // Table headers
        booking: 'Booking',
        traveldate: 'Travel Date',
        status: 'Status',
        invoices: 'Invoices',
        transactions: 'Transactions',
        itineraries: 'Itineraries',
        relatedref: 'Related Reference',
        viewinvoice: 'View Invoice',
        // View booking
        bookingoverview: 'Booking Overview',
        quoteoverview: 'Quote Overview',
        day: 'Day',
        price: 'Price',
        pricesmessage: 'Prices are NZD including GST 15%',
        pricesMessageGoway: 'Prices are AUD including GST 10%',
        finalpricingmessage: 'Final pricing is subject to the full confirmation you will receive from your consultant',
        downloadInvoice: 'Download Invoice',
        downloadCreditNote: 'Download Credit Note',
        // Account summary
        accountsummary: 'Account Summary',
        date: 'Date',
        type: 'Type',
        value: 'Value',
        // Quick Quote
        settings: 'Settings',
        accommodationclass: 'Accommodation Class',
        room: 'Room',
        adult: 'Adult',
        adults: 'Adults',
        addroom: 'Add Room',
        removeroom: 'Remove Room',
        getprice: 'Get Price',
        fetchingprice: 'Fetching Price',
        findingservices: 'Finding Services',
        savequote: 'Save Quote & Customise',
        adjustquote: 'Make More Changes',
        quotedetails: 'Quote Details',
        saving: 'Saving',
        customisequote: 'Customise Quote',
        downloaditinerary: 'Download Itinerary',
        preparingdownload: 'Preparing Download',
        copy: 'Copy Quote',
        selectoption: 'Select Option',
        requestbooking: 'Request Booking',
        priceformmessage: 'Services highlighted in red have no rates available based on your selected travel date and will not be saved to your quote. Alternatives can be added to your quote from the customise screen after the quote is saved.',
        priceestimatemessage: 'Estimated price. Subject to change.',
        insertactivityhere: 'Insert Activity Here',
        additionaldetails: 'Additional Details',
        nametitle: 'Full Name & Title',
        breakfastnote: 'Additional cost will be confirmed by our consultant',
        // Clients
        clientDetailsForm: 'Please provide your contact details to help us contact you in case of emergency or disruptions that impact your travel plans.',
        itineraryTailText: 'For any assistance while on the road please use the live chat on this page. If no live chat consultants are available or your enquiry is urgent please call the number below.',
        thankYouForTravelling: 'Thank you for travelling with us.',
        config: 'Configuration',
        dates: 'Dates',
        start: 'Start',
        end: 'End',
        remarks: 'Remarks',
        phone: 'Phone',
        address: 'Address',
        confirmation: 'Confirmation',
        voucher: 'Voucher',
        feedback: 'Please leave your feedback about this service.',
        drivingNotes: 'Show Suggested Driving Notes',
        quoteIntroPD: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis cursus ligula. Mauris turpis arcu, vehicula sed sapien et, ultrices tempor nulla. Sed et vulputate metus, vitae fermentum lacus. Aenean sit amet pulvinar orci. Integer lacinia elementum aliquet. Phasellus non facilisis lectus, ut varius nisi. Sed elementum sit amet magna eget venenatis. Aliquam sed porta leo, eget tempus erat. Duis ac finibus nibh. Nunc euismod mi ut gravida bibendum. Sed neque nisi, ultricies ut dolor nec, consequat malesuada tortor. Nullam non auctor tellus, pharetra euismod massa. Aliquam volutpat imperdiet porta.\n' +
            '<br /><br />' +
            'Vivamus in rutrum erat. Aliquam et mi in ipsum accumsan finibus tincidunt eget elit. Nullam eget elit imperdiet, egestas ex eget, elementum elit. Maecenas ullamcorper enim ut dictum maximus. Aenean nisi eros, facilisis at accumsan vel, auctor vel justo. Nulla viverra mauris in ante euismod molestie. Vivamus non consectetur lorem, maximus varius mi. Nunc nunc ex, semper ac tempus eget, sagittis non odio. Vestibulum ut imperdiet ipsum.',
        quoteIntroGW: 'We have the pleasure in providing you with a quotation for your Australian travel arrangements.',
        quoteTailPD: '',
        quoteTailGW: 'This quote is subject to availability at the time of booking.  We reserve the right to use alternate suppliers for accommodation and touring, where a supplier is unavailable at the time of making the booking.  Some suppliers may have special conditions and/or require client information at the time of booking to confirm the service.  For additional details regarding your quote, please discuss with your booking agent.'
    },

    de: {
        // Login
        login: 'Anmeldung',
        username: 'Nutzername',
        password: 'Passwort',
        lostpassword: 'Passwort vergessen',
        passwordreset: 'Passwort zurücksetzen gesendet',
        passwordresetmessage: "Bitte geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein. Wenn Sie dies nicht wissen, wenden Sie sich bitte an ",
        sendpasswordreset: 'Passwort zurücksetzen senden',
        resetsent: 'Passwort zurücksetzen gesendet',
        resetsentmessage: 'Bitte überprüfen Sie Ihre E-Mail auf Ihren Link zum Zurücksetzen des Passworts.',
        setnewpassword: 'Neues Passwort Setzen',
        newpassword: 'Neues Kennwort',
        confirmnewpassword: 'Bestätige neues Passwort',
        resetsuccessful: 'Passwort zurücksetzen erfolgreich',
        pleaselogin: 'Bitte melden Sie sich mit Ihrem neuen Passwort an.',
        // Side bar menu
        quotesbookings: 'Bestehende Angebote und Buchungen',
            reference: 'Referenz',
            yourreference: 'Ihre Referenz',
            bookingname: 'Buchungsname',
            traveldatefrom: 'Reisedatum Ab',
            traveldateto: 'Travel Date To',
            filter: 'Ergebnisse filtern',
        customquote: 'Angebot/Buchung erstellen',
        quickquote: 'Erstellen Sie ein schnelles Angebot',
            duration: 'Dauer',
            startlocation: 'Startort',
            transporttype: 'Transporttyp',
            any: 'Beliebig',
            nights: 'Nächte',
            rentalcar: 'Mietwagen',
            seatincoach: 'Sitz im Reisebus',
            privatetransfer: 'Privater Transfer',
        tariff: 'Produkttarif',
        ourTools: 'Unsere Werkzeuge',
        tourplanTools: 'Tourenplan-Tools',
        logout: 'Ausloggen',
        documentation: 'Dokumentation',
        search: 'Suchen',
        reset: 'Zurücksetzen',
        // Table headers
        booking: 'Buchung',
        traveldate: 'Reise datum',
        status: 'Status',
        invoices: 'Rechnungen',
        transactions: 'Transaktionen',
        itineraries: 'Reiserouten',
        relatedref: 'Entsprechende Referenz',
        viewinvoice: 'Rechnung Ansehen',
        // View booking
        bookingoverview: 'Buchungsübersicht',
        quoteoverview: 'Angebotsübersicht',
        day: 'Tag',
        price: 'Preis',
        pricesmessage: 'Inklusive 15% GST, vorbehaltlich Änderungen',
        pricesMessageGoway: 'Inklusive 10% GST, vorbehaltlich Änderungen',
        finalpricingmessage: 'Die endgültige Preisgestaltung unterliegt der vollständigen Bestätigung, die Sie von Ihrem Berater erhalten',
        downloadInvoice: 'Rechnung herunterladen',
        downloadCreditNote: 'Gutschrift herunterladen',
        // Account summary
        accountsummary: 'Kontoübersicht',
        date: 'Datum',
        type: 'Typ',
        value: 'Wert',
        // Quick Quote
        settings: 'Einstellungen',
        accommodationclass: 'Unterkunftsklasse',
        room: 'Zimmer',
        adult: 'Erwachsene',
        adults: 'Erwachsene',
        addroom: 'Zimmer hinzufügen',
        removeroom: 'Zimmer entfernen',
        getprice: 'Preis Bekommen',
        fetchingprice: 'Preis Abrufen',
        findingservices: 'Dienstleistungen Finden',
        savequote: 'Angebot Speichern & Anpassen',
        adjustquote: 'Weitere Änderungen vornehmen',
        quotedetails: 'Angebotsdetails',
        saving: 'Sparen',
        customisequote: 'Angebot Anpassen',
        downloaditinerary: 'Reiseroute Herunterladen',
        preparingdownload: 'Download wird vorbereitet',
        copy: 'Zitat kopieren',
        selectoption: 'Wähle eine Option',
        requestbooking: 'Buchung Anfordern',
        priceformmessage: 'Für rot markierte Services sind basierend auf Ihrem ausgewählten Reisedatum keine Tarife verfügbar und sie werden nicht in Ihrem Angebot gespeichert. Nachdem das Angebot gespeichert wurde, können Sie Ihrem Angebot über den Anpassungsbildschirm Alternativen hinzufügen.',
        priceestimatemessage: 'Schätzpreis. Änderungen vorbehalten.',
        insertactivityhere: 'Aktivität hier einfügen',
        additionaldetails: 'Weitere Details',
        nametitle: 'Vollständiger Name und Titel',
        breakfastnote: 'Zusätzliche Kosten werden von unserem Berater bestätigt',
        // Clients
        clientDetailsForm: 'Bitte geben Sie Ihre Kontaktdaten an, damit wir Sie im Falle von Notfällen oder Störungen, die Ihre Reisepläne beeinträchtigen, erreichen können.',
        itineraryTailText: 'Wenn Sie unterwegs Hilfe benötigen, nutzen Sie bitte den Live-Chat auf dieser Seite. Wenn keine Live-Chat-Berater verfügbar sind oder Ihre Anfrage dringend ist, rufen Sie bitte die untenstehende Nummer an.',
        thankYouForTravelling: 'Vielen Dank, dass Sie mit uns gereist sind.',
        config: 'Aufbau',
        dates: 'Termine',
        start: 'Start',
        end: 'Ende',
        remarks: 'Bemerkungen',
        phone: 'Telefon',
        address: 'Adresse',
        confirmation: 'Bestätigung',
        voucher: 'Gutschein',
        feedback: 'Bitte hinterlassen Sie Ihr Feedback zu diesem Service.',
        drivingNotes: 'Vorgeschlagene Fahrhinweise anzeigen',
        quoteIntroPD: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis cursus ligula. Mauris turpis arcu, vehicula sed sapien et, ultrices tempor nulla. Sed et vulputate metus, vitae fermentum lacus. Aenean sit amet pulvinar orci. Integer lacinia elementum aliquet. Phasellus non facilisis lectus, ut varius nisi. Sed elementum sit amet magna eget venenatis. Aliquam sed porta leo, eget tempus erat. Duis ac finibus nibh. Nunc euismod mi ut gravida bibendum. Sed neque nisi, ultricies ut dolor nec, consequat malesuada tortor. Nullam non auctor tellus, pharetra euismod massa. Aliquam volutpat imperdiet porta.\n' +
            '<br /><br />' +
            'Vivamus in rutrum erat. Aliquam et mi in ipsum accumsan finibus tincidunt eget elit. Nullam eget elit imperdiet, egestas ex eget, elementum elit. Maecenas ullamcorper enim ut dictum maximus. Aenean nisi eros, facilisis at accumsan vel, auctor vel justo. Nulla viverra mauris in ante euismod molestie. Vivamus non consectetur lorem, maximus varius mi. Nunc nunc ex, semper ac tempus eget, sagittis non odio. Vestibulum ut imperdiet ipsum.',
        quoteIntroGW: 'Gerne unterbreiten wir Ihnen ein Angebot für Ihre Australien-Reisearrangements.',
        quoteTailPD: '',
        quoteTailGW: 'Dieses Angebot unterliegt der Verfügbarkeit zum Zeitpunkt der Buchung. Wir behalten uns das Recht vor, alternative Anbieter für Unterkunft und Touren zu nutzen, wenn ein Anbieter zum Zeitpunkt der Buchung nicht verfügbar ist. Einige Anbieter haben möglicherweise besondere Bedingungen und/oder benötigen zum Zeitpunkt der Buchung Kundeninformationen, um die Dienstleistung zu bestätigen. Weitere Einzelheiten zu Ihrem Angebot besprechen Sie bitte mit Ihrem Buchungsagenten.'
    },

    es: {
        // Login
        login: 'Acceso',
        username: 'Nombre de Usuario',
        password: 'Contraseña',
        lostpassword: '¿Contraseña perdida',
        passwordreset: 'Restablecimiento de contraseña enviado',
        passwordresetmessage: "Ingrese la dirección de correo electrónico asociada con su cuenta. Si no sabe esto, comuníquese con ",
        sendpasswordreset: 'Enviar restablecimiento de contraseña',
        resetsent: 'Restablecimiento de contraseña enviado',
        resetsentmessage: 'Por favor revise su correo electrónico para su enlace de restablecimiento de contraseña.',
        setnewpassword: 'Establecer nueva contraseña',
        newpassword: 'Nueva contraseña',
        confirmnewpassword: 'Confirmar nueva contraseña',
        resetsuccessful: 'Restablecimiento de contraseña exitoso',
        pleaselogin: 'Por favor inicie sesión con su nueva contraseña',
        // Side bar menu
        quotesbookings: 'Cotizaciones y reservas existentes',
            reference: 'Referencia',
            yourreference: 'Tu Referencia',
            bookingname: 'Nombre de la Reserva',
            traveldatefrom: 'Fecha de Viaje Desde',
            traveldateto: 'Travel Date To',
            filter: 'Filtrar resultados',
        customquote: 'Crear Cotización/Reserva',
        quickquote: 'Crear cotización rápida',
            duration: 'Duración',
            startlocation: 'Ubicación de Inicio',
            transporttype: 'Tipo de Transporte',
            any: 'Cualquier',
            nights: 'Noches',
            rentalcar: 'Coche de Alquiler',
            seatincoach: 'Asiento en Autocar',
            privatetransfer: 'Transferencia privada',
        tariff: 'Tarifa del producto',
        ourTools: 'Nuestras Herramientas',
        tourplanTools: 'Herramientas de Tourplan',
        logout: 'Cerrar Sesión',
        documentation: 'Documentación',
        search: 'Buscar',
        reset: 'Reiniciar',
        // Table headers
        booking: 'Reserva',
        traveldate: 'Fecha de Viaje',
        status: 'Estado',
        invoices: 'Facturas',
        transactions: 'Actas',
        itineraries: 'Itinerarios',
        relatedref: 'Referencia Relacionada',
        viewinvoice: 'Mirar la Factura',
        // View booking
        bookingoverview: 'Descripción General de la Reserva',
        quoteoverview: 'Descripción General de la Cotización',
        day: 'Día',
        price: 'Precio',
        pricesmessage: "Colazione esclusa & incl. il 15% GST, soggettivo all'aumento di prezzo",
        pricesMessageGoway: "Colazione esclusa & incl. il 10% GST, soggettivo all'aumento di prezzo",
        finalpricingmessage: 'El precio final está sujeto a la confirmación completa que recibirá de su asesor',
        downloadInvoice: 'Descargar factura',
        downloadCreditNote: 'Descargar Nota de Crédito',
        // Account summary
        accountsummary: 'Resumen de la Cuenta',
        date: 'Fecha',
        type: 'Tipo',
        value: 'Valor',
        // Quick Quote
        settings: 'Ajustes',
        accommodationclass: 'Clase de Alojamiento',
        room: 'Habitación',
        adult: 'Adulto',
        adults: 'Adultos',
        addroom: 'Añadir habitación',
        removeroom: 'Eliminar habitación',
        getprice: 'Obtener Precio',
        fetchingprice: 'Precio de Venta',
        findingservices: 'Encontrar Servicios',
        savequote: 'Guardar Presupuesto y Personalizar',
        adjustquote: 'Hacer más cambios',
        quotedetails: 'Detalles de cotización',
        saving: 'Ahorro',
        customisequote: 'Personalizar Presupuesto',
        downloaditinerary: 'Descargar Itinerario',
        preparingdownload: 'Preparando Descarga',
        copy: 'Copiar Cita',
        selectoption: 'Seleccionar Opción',
        requestbooking: 'Solicitar Reserva',
        priceformmessage: 'Los servicios resaltados en rojo no tienen tarifas disponibles según la fecha de viaje seleccionada y no se guardarán en su cotización. Se pueden agregar alternativas a su cotización desde la pantalla de personalización después de guardar la cotización.',
        priceestimatemessage: 'Precio estimado. Sujeto a cambios.',
        insertactivityhere: 'Insertar actividad aquí',
        additionaldetails: 'Detalles Adicionales',
        nametitle: 'Nombre completo y título',
        breakfastnote: 'Costo adicional será confirmado por nuestra consultora',
        // Clients
        clientDetailsForm: 'Por favor, proporcione sus datos de contacto para que podamos comunicarnos con usted en caso de emergencia o interrupciones que afecten sus planes de viaje.',
        itineraryTailText: 'Para obtener ayuda mientras viaja, utilice el chat en vivo en esta página. Si no hay consultores de chat en vivo disponibles o su consulta es urgente, llame al número a continuación.',
        thankYouForTravelling: 'Gracias por viajar con nosotros.',
        config: 'Configuración',
        dates: 'Fechas',
        start: 'Comenzar',
        end: 'Fin',
        remarks: 'Comentarios',
        phone: 'Teléfono',
        address: 'Dirección',
        confirmation: 'Confirmación',
        voucher: 'Vale',
        feedback: 'Por favor deje sus comentarios sobre este servicio.',
        drivingNotes: 'Mostrar notas de conducción sugeridas',
        quoteIntroPD: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis cursus ligula. Mauris turpis arcu, vehicula sed sapien et, ultrices tempor nulla. Sed et vulputate metus, vitae fermentum lacus. Aenean sit amet pulvinar orci. Integer lacinia elementum aliquet. Phasellus non facilisis lectus, ut varius nisi. Sed elementum sit amet magna eget venenatis. Aliquam sed porta leo, eget tempus erat. Duis ac finibus nibh. Nunc euismod mi ut gravida bibendum. Sed neque nisi, ultricies ut dolor nec, consequat malesuada tortor. Nullam non auctor tellus, pharetra euismod massa. Aliquam volutpat imperdiet porta.\n' +
            '<br /><br />' +
            'Vivamus in rutrum erat. Aliquam et mi in ipsum accumsan finibus tincidunt eget elit. Nullam eget elit imperdiet, egestas ex eget, elementum elit. Maecenas ullamcorper enim ut dictum maximus. Aenean nisi eros, facilisis at accumsan vel, auctor vel justo. Nulla viverra mauris in ante euismod molestie. Vivamus non consectetur lorem, maximus varius mi. Nunc nunc ex, semper ac tempus eget, sagittis non odio. Vestibulum ut imperdiet ipsum.',
        quoteIntroGW: 'Tenemos el placer de ofrecerle una cotización para sus arreglos de viaje a Australia.',
        quoteTailPD: '',
        quoteTailGW: 'Esta cotización está sujeta a disponibilidad al momento de realizar la reserva. Nos reservamos el derecho de utilizar proveedores alternativos para alojamiento y viajes, cuando un proveedor no esté disponible en el momento de realizar la reserva. Algunos proveedores pueden tener condiciones especiales y/o requerir información del cliente al momento de realizar la reserva para confirmar el servicio. Para obtener detalles adicionales sobre su cotización, hable con su agente de reservas.'
    },

    it: {
        // Login
        login: 'Login',
        username: 'Nome Utente',
        password: "Parola d'ordine",
        lostpassword: 'Password dimenticata',
        passwordreset: 'Reimpostazione della password inviata',
        passwordresetmessage: "Si prega di inserire l'indirizzo email associato al tuo account. Se non lo sai, contatta ",
        sendpasswordreset: 'Inviare la reimpostazione della password',
        resetsent: 'Reimpostazione della password inviata',
        resetsentmessage: 'Controlla la tua e-mail per il link per reimpostare la password.',
        setnewpassword: 'Impostare una nuova password',
        newpassword: 'Nuova Password',
        confirmnewpassword: 'Conferma la nuova password',
        resetsuccessful: 'Reimpostazione password riuscita',
        pleaselogin: 'Effettua il login con la tua nuova password.',
        // Side bar menu
        quotesbookings: 'Preventivi e prenotazioni esistenti',
            reference: 'Riferimento',
            yourreference: 'Il Tuo Riferimento',
            bookingname: 'Nome Della Prenotazione',
            traveldatefrom: 'Data Del Viaggio Da',
            traveldateto: 'Travel Date To',
            filter: 'Filtra risultati',
        customquote: 'Creare Preventivo/Prenotazione',
        quickquote: 'Crea un preventivo rapido',
            duration: 'Durata',
            startlocation: 'Posizione di Partenza',
            transporttype: 'Tipo di Trasporto',
            any: 'Qualunque',
            nights: 'Notti',
            rentalcar: 'Auto a Noleggio',
            seatincoach: 'Posto in Carrozza',
            privatetransfer: 'Trasferimento privato',
        tariff: 'Tariffa del prodotto',
        ourTools: 'I Nostri Strumenti',
        tourplanTools: 'Strumenti del Tourplan',
        logout: 'Disconnettersi',
        documentation: 'documentación',
        search: 'Ricerca',
        reset: 'Ripristina',
        // Table headers
        booking: 'Prenotazione',
        traveldate: 'Data di Viaggio',
        status: 'Stato',
        invoices: 'Fatture',
        transactions: 'Transazioni',
        itineraries: 'Itinerari',
        relatedref: 'Relativo Riferimento',
        viewinvoice: 'Visualizza Fattura',
        // View booking
        bookingoverview: 'Panoramica Della Prenotazione',
        quoteoverview: 'Panoramica Delle Quotazioni',
        day: 'Giorno',
        price: 'Prezzo',
        pricesmessage: 'Incluido 15% de GST, sujeto a modificaciones',
        pricesMessageGoway: 'Incluido 10% de GST, sujeto a modificaciones',
        finalpricingmessage: 'Il prezzo finale è soggetto alla conferma completa che riceverai dal tuo consulente',
        downloadInvoice: 'Scarica fattura',
        downloadCreditNote: 'Scarica la nota di credito',
        // Account summary
        accountsummary: 'Riepilogo Account',
        date: 'Data',
        type: 'Tipo',
        value: 'Valore',
        // Quick Quote
        settings: 'Impostazioni',
        accommodationclass: 'Classe di Alloggio',
        room: 'Camera',
        adult: 'Adulto',
        adults: 'Adulti',
        addroom: 'Aggiungi stanza',
        removeroom: 'Rimuovere stanza',
        getprice: 'Ottenere il Prezzo',
        fetchingprice: 'Prezzo di Recupero',
        findingservices: 'Trovare Servizi',
        savequote: 'Salva Preventivo e Personalizza',
        adjustquote: 'Apporta ulteriori modifiche',
        quotedetails: 'Dettagli preventivo',
        saving: 'Salvataggio',
        customisequote: 'Personalizzare il Preventivo',
        downloaditinerary: "Scarica L'itinerario",
        preparingdownload: 'Preparazione del download',
        copy: 'Copia Citazione',
        selectoption: "Selezionare l'opzione",
        requestbooking: 'Richiesta Prenotazione',
        priceformmessage: 'I servizi evidenziati in rosso non hanno tariffe disponibili in base alla data di viaggio selezionata e non verranno salvati nel tuo preventivo. Le alternative possono essere aggiunte al tuo preventivo dalla schermata di personalizzazione dopo che il preventivo è stato salvato.',
        priceestimatemessage: 'Prezzo stimato. Soggetto a modifiche.',
        insertactivityhere: 'Inserisci attività qui',
        additionaldetails: 'Dettagli Aggiuntivi',
        nametitle: 'Nome completo e titolo',
        breakfastnote: 'Il costo aggiuntivo sarà confermato dal nostro consulente',
        // Clients
        clientDetailsForm: 'Per favore, fornisci i tuoi dati di contatto in modo che possiamo contattarti in caso di emergenza o interruzioni che influenzino i tuoi piani di viaggio.',
        itineraryTailText: 'Per qualsiasi assistenza mentre sei in viaggio, utilizza la live chat in questa pagina. Se non sono disponibili consulenti di chat dal vivo o la tua richiesta è urgente, chiama il numero sottostante.',
        thankYouForTravelling: 'Grazie per aver viaggiato con noi.',
        config: 'Configurazione',
        dates: 'Date',
        start: 'Inizio',
        end: 'Fine',
        remarks: 'Osservazioni',
        phone: 'Telefono',
        address: 'Indirizzo',
        confirmation: 'Conferma',
        voucher: 'Tagliando',
        feedback: 'Per favore lascia il tuo feedback su questo servizio.',
        drivingNotes: 'Mostra note di guida suggerite',
        quoteIntroPD: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis cursus ligula. Mauris turpis arcu, vehicula sed sapien et, ultrices tempor nulla. Sed et vulputate metus, vitae fermentum lacus. Aenean sit amet pulvinar orci. Integer lacinia elementum aliquet. Phasellus non facilisis lectus, ut varius nisi. Sed elementum sit amet magna eget venenatis. Aliquam sed porta leo, eget tempus erat. Duis ac finibus nibh. Nunc euismod mi ut gravida bibendum. Sed neque nisi, ultricies ut dolor nec, consequat malesuada tortor. Nullam non auctor tellus, pharetra euismod massa. Aliquam volutpat imperdiet porta.\n' +
            '<br /><br />' +
            'Vivamus in rutrum erat. Aliquam et mi in ipsum accumsan finibus tincidunt eget elit. Nullam eget elit imperdiet, egestas ex eget, elementum elit. Maecenas ullamcorper enim ut dictum maximus. Aenean nisi eros, facilisis at accumsan vel, auctor vel justo. Nulla viverra mauris in ante euismod molestie. Vivamus non consectetur lorem, maximus varius mi. Nunc nunc ex, semper ac tempus eget, sagittis non odio. Vestibulum ut imperdiet ipsum.',
        quoteIntroGW: "Abbiamo il piacere di fornirti un preventivo per l'organizzazione del tuo viaggio in Australia.",
        quoteTailPD: '',
        quoteTailGW: 'Questa quotazione è soggetta a disponibilità al momento della prenotazione. Ci riserviamo il diritto di utilizzare fornitori alternativi per l\'alloggio e il tour, laddove un fornitore non sia disponibile al momento della prenotazione. Alcuni fornitori potrebbero applicare condizioni speciali e/o richiedere informazioni al cliente al momento della prenotazione per confermare il servizio. Per ulteriori dettagli sul preventivo, parlane con il tuo agente di prenotazione.'
    },

    fr: {
        // Login
        login: 'Se connecter',
        username: "Nom d'utilisateur",
        password: 'Mot de passe',
        lostpassword: 'Mot de passe perdu',
        passwordreset: 'Réinitialisation du mot de passe envoyée',
        passwordresetmessage: "Veuillez saisir l'adresse e-mail associée à votre compte. Si vous ne le savez pas, veuillez contacter ",
        sendpasswordreset: 'Envoyer la réinitialisation du mot de passe',
        resetsent: 'Réinitialisation du mot de passe envoyée',
        resetsentmessage: 'Veuillez vérifier votre courrier électronique pour le lien de réinitialisation de votre mot de passe.',
        setnewpassword: 'Definir un nouveau mot de passe',
        newpassword: 'Nouveau mot de passe',
        confirmnewpassword: 'Confirmer le nouveau mot de passe',
        resetsuccessful: 'Réinitialisation du mot de passe réussie',
        pleaselogin: 'Veuillez vous connecter avec votre nouveau mot de passe.',
        // Side bar menu
        quotesbookings: 'Devis et réservations existants',
        reference: 'Référence',
        yourreference: 'Ta référence',
        bookingname: 'Nom de la réservation',
        traveldatefrom: 'Date du voyage à partir de',
        filter: 'Filtrer les résultats',
        customquote: 'Créer un devis/réservation',
        quickquote: 'Créer un devis rapide',
        duration: 'Durée',
        startlocation: 'Lieu de départ',
        transporttype: 'Type de transport',
        any: "N'importe lequel",
        nights: 'Nuits',
        rentalcar: 'Voiture de location',
        seatincoach: 'Siège en coach',
        privatetransfer: 'Transfert privé',
        tariff: 'Tarif du produit',
        ourTools: 'Nos outils',
        tourplanTools: 'Outils de Tourplan',
        logout: 'Se déconnecter',
        documentation: 'Documentation',
        search: 'Recherche',
        reset: 'Réinitialiser',
        // Table headers
        booking: 'Réservation',
        traveldate: 'Date de voyage',
        status: 'Statut',
        invoices: 'Factures',
        transactions: 'Transactions',
        itineraries: 'Itinéraires',
        relatedref: 'Référence connexe',
        viewinvoice: 'Voir la facture',
        // View booking
        bookingoverview: 'Aperçu de la réservation',
        quoteoverview: 'Aperçu du devis',
        day: 'Jour',
        price: 'Prix',
        pricesmessage: 'Les prix sont en NZD, GST 15 % comprise',
        pricesMessageGoway: 'Les prix sont en AUD, GST 10 % comprise',
        finalpricingmessage: 'Le prix final est soumis à la confirmation complète que vous recevrez de votre consultant',
        downloadInvoice: 'Télécharger la facture',
        downloadCreditNote: 'Télécharger la note de crédit',
        // Account summary
        accountsummary: 'Relevé de compte',
        date: 'Date',
        type: 'Taper',
        value: 'Valeur',
        // Quick Quote
        settings: 'Paramètres',
        accommodationclass: 'Classe d\'hébergement',
        room: 'Chambre',
        adult: 'Adulte',
        adults: 'Adultes',
        addroom: 'Ajouter une pièce',
        removeroom: 'Supprimer la pièce',
        getprice: 'Obtenir le prix',
        fetchingprice: 'Récupération du prix',
        findingservices: 'Trouver des services',
        savequote: 'Enregistrer le devis et personnaliser',
        adjustquote: 'Apporter plus de modifications',
        quotedetails: 'Détails du devis',
        saving: 'Stocker des données',
        customisequote: 'Personnaliser le devis',
        downloaditinerary: 'Télécharger l\'itinéraire',
        preparingdownload: 'Préparation du téléchargement',
        copy: 'Copier le devis',
        selectoption: 'Sélectionnez une option',
        requestbooking: 'Demander une réservation',
        priceformmessage: 'Les services surlignés en rouge n\'ont pas de tarifs disponibles en fonction de la date de voyage sélectionnée et ne seront pas enregistrés dans votre devis. Des alternatives peuvent être ajoutées à votre devis à partir de l’écran de personnalisation une fois le devis enregistré.',
        priceestimatemessage: 'Prix estimé. Sous réserve de modifications.',
        insertactivityhere: 'Insérer une activité ici',
        additionaldetails: 'Détails supplémentaires',
        nametitle: 'Nom complet et titre',
        breakfastnote: 'Le coût supplémentaire sera confirmé par notre consultant',
        // Clients
        clientDetailsForm: 'Veuillez fournir vos coordonnées afin que nous puissions vous contacter en cas d\'urgence ou de perturbations affectant vos plans de voyage.',
        clientContactMessage: 'Veuillez fournir vos coordonnées pour nous aider à vous contacter en cas d\'urgence ou de perturbations ayant un impact sur vos projets de voyage.',
        itineraryTailText: 'Pour toute assistance sur la route, veuillez utiliser le chat en direct sur cette page. Si aucun consultant de chat en direct n\'est disponible ou si votre demande est urgente, veuillez appeler le numéro ci-dessous.',
        thankYouForTravelling: "Merci d'avoir voyagé avec nous.",
        config: 'Configuration',
        dates: 'Rendez-vous',
        start: 'Départ',
        end: 'Fin',
        remarks: 'Remarques',
        phone: 'Téléphone',
        address: 'Adresse',
        confirmation: 'Confirmation',
        voucher: 'Voucher',
        feedback: 'Veuillez laisser vos commentaires sur ce service.',
        drivingNotes: 'Afficher les notes de conduite suggérées',
        quoteIntroPD: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis cursus ligula. Mauris turpis arcu, vehicula sed sapien et, ultrices tempor nulla. Sed et vulputate metus, vitae fermentum lacus. Aenean sit amet pulvinar orci. Integer lacinia elementum aliquet. Phasellus non facilisis lectus, ut varius nisi. Sed elementum sit amet magna eget venenatis. Aliquam sed porta leo, eget tempus erat. Duis ac finibus nibh. Nunc euismod mi ut gravida bibendum. Sed neque nisi, ultricies ut dolor nec, consequat malesuada tortor. Nullam non auctor tellus, pharetra euismod massa. Aliquam volutpat imperdiet porta.\n' +
            '<br /><br />' +
            'Vivamus in rutrum erat. Aliquam et mi in ipsum accumsan finibus tincidunt eget elit. Nullam eget elit imperdiet, egestas ex eget, elementum elit. Maecenas ullamcorper enim ut dictum maximus. Aenean nisi eros, facilisis at accumsan vel, auctor vel justo. Nulla viverra mauris in ante euismod molestie. Vivamus non consectetur lorem, maximus varius mi. Nunc nunc ex, semper ac tempus eget, sagittis non odio. Vestibulum ut imperdiet ipsum.',
        quoteIntroGW: 'Nous avons le plaisir de vous fournir un devis pour vos préparatifs de voyage en Australie.',
        quoteTailPD: '',
        quoteTailGW: 'Ce devis est soumis à disponibilité au moment de la réservation. Nous nous réservons le droit de faire appel à d\'autres fournisseurs pour l\'hébergement et les visites, lorsqu\'un fournisseur n\'est pas disponible au moment de la réservation. Certains fournisseurs peuvent avoir des conditions particulières et/ou exiger des informations sur le client au moment de la réservation pour confirmer le service. Pour plus de détails concernant votre devis, veuillez en discuter avec votre agent de réservation.'
    },

}
