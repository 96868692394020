<template>
    <button v-bind="$attrs" class="w-full text-nowrap text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700" :class="ext"><i v-if="icon != ''" class="mr-2" :class="icon" ></i>{{ buttonText }}</button>
</template>

<script>
    export default {
        props: {
            buttonText: {
                type: String,
                default: () => "Submit",
            },
            icon: {
                type: String,
                default: () => ''
            },
            ext: {
                type: String,
                default: () => ''
            }
        }
    }

</script>
