import { createI18n } from 'vue-i18n'
import messages from './messages'
const i18n = createI18n({
    legacy: false,
  // default locale

  locale: 'en',

  messages,

})

//i18n.global.locale = 'en'

export default i18n
