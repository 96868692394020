import { defineStore } from "pinia";

export const useEnvSettingsStore = defineStore("settings", {
    state: () => ({
        env: null,
        clean: true
    }),
    actions: {
        async getSettings(){
            this.clean = true
            try {
                axios
                .get('/api/get-settings')
                .then(({data})=>{
                    this.env = data
                    this.clean = false
                }).catch((error)=>{
                    console.error('Invalid response!');
                })
            } catch (error) {
                console.error('Failed to fetch settings:', error);
            }
        }
    }
})
